import { ExpertiseSectionMock } from '~/types/mock'

const MvpWebDevBusinessType: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'MVP development tailored for startups',
    description:
      'Our team devotes maximum effort to market analysis and strategic planning when providing MVP development services for startups. We aim to validate your idea and align it with market demands.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'MVP development for small businesses',
    description:
      'Growing a business may seem challenging, but starting with MVP development can streamline your path to the next level. We ensure a great customer experience with minimal effort, saving you valuable time and resources.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'MVP development for mid-scale businesses',
    description:
      'For businesses of medium scale, our MVP development services offer swift delivery and cost-effective investments. Our agile approach enables meticulous development management, minimizing the risk of defects.',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'MVP development for enterprises',
    description:
      'We specialize in creating enterprise MVPs tailored to suit their specific corporate IT requirements and unique characteristics. Our specialists excel at transforming MVPs into fully functional products that remain relevant in the market.',
  },
]

export default MvpWebDevBusinessType
