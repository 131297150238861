import { ExpertiseSectionMock } from '~/types/mock'

const MvpWebDevPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_76',
    title: 'Expert idea validation',
    description:
      'Our team cares that your solution will be successful on the market, so we are always ready to conduct thorough research on your idea’s validity and provide consultations.',
  },
  {
    icon: 'spriteIcon_79',
    title: 'Agile development methodology',
    description:
      'Our team follows an agile development approach, ensuring flexibility and adaptability throughout the project lifecycle. This enables us to deliver results efficiently and effectively.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Higher conversions and sales',
    description:
      'By meticulously aligning user journeys with intuitive UX designs, we create engaging platforms that captivate and convert customers. Our focus is on maximizing your conversion rate.',
  },
  {
    icon: 'spriteIcon_62',
    title: 'Industry-leading standards',
    description:
      'We stay up-to-date with the latest industry trends and standards, sharing our expertise with you to create web solutions that are in line with the best practices of the marketplace.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Passionate and dedicated team',
    description:
      'We value effective communication, flexibility, and the cultivation of long-lasting, mutually beneficial relationships. Our dedication ensures a successful partnership.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Continuous support post-launch',
    description:
      'Our marketplace development services extend beyond the launch of your site. We provide ongoing assistance, ensuring the smooth operation and optimal performance of your platform.',
  },
]

export default MvpWebDevPractices
