import { ServicesProcessMock } from '~/types/mock'

const MvpWebDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Exploration and analysis',
    descr:
      'In the initial stage, our seasoned team gets an image of your concept and the underlying problem you seek to address.',
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr:
      'During this phase, we craft the interfaces for your MVP product. We map out the user journey through prototyping and ensure an intuitive website.',
  },
  {
    num: '03',
    title: 'MVP development',
    descr:
      'This is where programming takes center stage. By leveraging the most suitable tech stack and the latest industry standards, we develop the MVP.',
  },
  {
    num: '04',
    title: 'QA and optimization',
    descr:
      'Our comprehensive testing of the MVP website and code optimization guarantees its stability, user satisfaction, and overall excellence of your solution.',
  },
  {
    num: '05',
    title: 'Deployment & support',
    descr:
      'We diligently monitor the software, promptly resolving any issues that may surface. Our team provides regular maintenance and support of your website.',
  },
]

export default MvpWebDevProcess
