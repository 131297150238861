// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "x_dm";
export var mvpWebDevBusinessTypeSection = "x_dj";
export var mvpWebDevCaseSection = "x_dq";
export var mvpWebDevClientQuotes = "x_dr";
export var mvpWebDevExpertiseSection = "x_dp";
export var mvpWebDevIndustriesSection = "x_dh";
export var mvpWebDevPracticesSection = "x_dn";
export var mvpWebDevPrimeSection = "x_df";
export var mvpWebDevProcessSection = "x_dk";
export var mvpWebDevServicesSection = "x_dg";
export var mvpWebDevTechStackSection = "x_dl";