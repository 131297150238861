import { ExpertiseSectionMock } from '~/types/mock'

const MvpWebDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_66',
    title: 'Rapid launch',
    description:
      'By focusing on the core functionality, there is no need to dedicate excessive time or resources to develop a fully-featured product.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Cost minimization',
    description:
      'With fewer features to validate your idea, you will be impressed with significant MVP web development cost savings.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Quick idea testing',
    description:
      'Gather feedback from early adopters to assess whether further expansion of the website is warranted or if it is best to correct the development direction.',
  },
  {
    icon: 'spriteIcon_74',
    title: 'Iterative development',
    description:
      'The MVP approach facilitates a continuous improvement process through iterative updates driven by user feedback.',
  },
  {
    icon: 'spriteIcon_65',
    title: 'Mitigated risk',
    description:
      'With the MVP’s focus on essential features, the development process minimizes the risk of creating a product that fails to meet market demands or gain traction.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Investors appeal',
    description:
      'Positive user experiences and feedback also serve as compelling factors in attracting potential investors who recognize the product’s value.',
  },
]

export default MvpWebDevBenefits
