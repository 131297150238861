import { FAQ } from '~/types/data-array'

const MvpWebDevFAQ: FAQ[] = [
  {
    question: 'What is MVP in web development?',
    answer:
      'An MVP in mobile development, as in website creation, is a version of your web product that is ready for the market and contains the essential functionality necessary to fulfill a specific user requirement.',
  },
  {
    question: 'What is the typical cost of developing an MVP website?',
    answer:
      'The estimated cost to build an MVP website usually falls in the range of $15,000 to $50,000. However, the actual MVP cost may vary depending on factors such as the project’s scope, team composition, and other specific requirements.',
  },
  {
    question: 'Why do you need MVP development services?',
    answer:
      'By launching the minimum viable product, you can enter the market more swiftly and gather valuable user feedback ahead of competitors with fully-featured applications. This feedback loop enables you to make informed improvements and fine-tune your offering for greater success.',
  },
]

export default MvpWebDevFAQ
