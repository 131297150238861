import { HomeSpecification } from '~/types/mock'

const MvpWebDevIndustries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'At Codica, we empower online businesses to create robust platforms that connect buyers and sellers seamlessly.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'Our quality MVP website development services will enable businesses to sell products online quickly and increase revenue.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'Codica’s MVP web creation services for travel facilitate travel bookings and enhance the overall travel experience.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'The MVP web creation services for the automotive industry we offer at Codica facilitate the buying and selling of cars.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'With MVP web development, you will streamline the insurance process for customers and insurance providers.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'By creating an MVP web solution, you will make the hiring process convenient for both job seekers and employers.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Expert MVP website development services enhance the rental process for renters and providers on the online platform.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'We offer MVP web creation services providing engaging and interactive experiences for users consuming media content.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'MVP web development for healthcare will enhance patient care and improve the efficiency of healthcare processes.',
    link: '/case-studies/fitness-progressive-web-application/',
  },
]

export default MvpWebDevIndustries
