import { ExpertiseSectionMock } from '~/types/mock'

const MvpWebDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'MVP idea validation',
    link: '/services/product-discovery/',
    description:
      'Idea validation is the process of evaluating the potential success of a business idea through product discovery sessions. It involves measuring market demand and thorough analysis.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design',
    link: '/services/ui-ux-design/',
    description:
      'We offer comprehensive UI/UX design services aimed at creating intuitive and visually appealing user interfaces for your minimum viable product.',
  },
  {
    icon: 'sprite3DIcon_3',
    title: 'Full-fledged MVP development',
    description:
      'A fully functional minimum viable product is a finished product you can present to your target group to define whether it alleviates their issues.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Quality assurance',
    link: '/services/quality-assurance/',
    description:
      'We provide comprehensive quality assurance (QA) MVP services to ensure the functionality, stability, and reliability of your website with core functionality.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Maintenance and support',
    description:
      'We offer quality maintenance and support services for MVP web applications, ensuring their smooth operation and providing ongoing assistance.',
  },
]

export default MvpWebDevServices
